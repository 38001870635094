// Vendors
import React from 'react';
import { AssetComponent } from '@openbank/cb-ui-commons';
import { isAndroidDevice } from '@openbank/cf-ui-framework';
// Configurations
import {
  getAppStoreLinkConfiguration,
  getGooglePlayLinkConfiguration,
} from '../configurations/banner-download-app.configuration';
// Constants
import {
  MOBILE_APP_GOOGLE_PLAY_ASSET_PROPS,
  MOBILE_APP_APP_STORE_ASSET_PROPS,
} from '../constants/banner-download-app.constants';
// Styles
import { DownloadAppButtonWrapper } from './download-app-button.component.styled';

export const DownloadAppButtonComponent = (): React.ReactElement => {
  return (
    <DownloadAppButtonWrapper>
      {isAndroidDevice() ? (
        <AssetComponent
          {...{ ...MOBILE_APP_GOOGLE_PLAY_ASSET_PROPS, ...getGooglePlayLinkConfiguration() }}
        />
      ) : (
        <AssetComponent
          {...{ ...MOBILE_APP_APP_STORE_ASSET_PROPS, ...getAppStoreLinkConfiguration() }}
        />
      )}
    </DownloadAppButtonWrapper>
  );
};
