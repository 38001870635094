export const LINK = {
  PLAY_STORE_LINK_CLICK: {
    eventAction: 'clickButton',
    eventLabel: 'downloadPlayStoreButton',
  },
  APP_STORE_LINK_CLICK: {
    eventAction: 'clickButton',
    eventLabel: 'downloadAppStoreButton',
  },
};
