// Vendors
import React, { FC } from 'react';
// Styles
import { BoldMessageComponentStyled } from './bold-message.component.styled';
// Types
import { BoldMessageComponentPropsType } from './types/bold-message.component.type';

const BoldMessageComponent: FC<BoldMessageComponentPropsType> = ({
  children,
  isSafariBrowser,
  testId,
}) => (
  <BoldMessageComponentStyled {...{ isSafariBrowser, testId }}>{children}</BoldMessageComponentStyled>
);

export default BoldMessageComponent;
