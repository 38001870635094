// Enumerations
import { SessionEnumeration } from 'enumerations/sessions.enumerations';
// Types
import {
  DownloadAppModalProviderHandlersPropsType,
  DownloadAppModalProviderHandlersReturnType,
} from './types/download-app-modal.provider.handlers.types';

const closeModalHandler = ({
  setIsModalOpen,
}: Pick<DownloadAppModalProviderHandlersPropsType, 'setIsModalOpen'>) => {
  setIsModalOpen(false);
  sessionStorage.setItem(SessionEnumeration.MODAL_DOWNLOAD_APP_OPENED_SESSION_KEY, 'true');
};


export const DownloadAppModalProviderHandlers = (
  props: DownloadAppModalProviderHandlersPropsType
): DownloadAppModalProviderHandlersReturnType => ({
  handleCloseModal: () => closeModalHandler(props),
});
