// Vendors
import styled from 'styled-components';
// Types
import { TestIdPropsType } from 'types/test-id.type';

export const BoldMessageComponentStyled = styled.b.attrs(
  ({ isSafariBrowser, testId }: TestIdPropsType) => ({
    ...(testId ? { 'data-testid': testId } : {}),
    isSafariBrowser,
  })
)`
  font-family: ${({ theme }) => theme.fontFamilies.fontFamilyA};
  font-weight: ${({ isSafariBrowser, theme }) =>
    isSafariBrowser && theme.fontWeights.fontWeight400};
`;
