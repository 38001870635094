// Vendors
import { createGlobalStyle } from 'styled-components';
// Theme features
import { AmazonTheme } from '@openbank/cf-ui-static-data';

const ThemeProviderStyled = createGlobalStyle`
  body,
  html {
    font-size: 16px;
    font-family: ${AmazonTheme.default.fontFamilies.fontFamilyA};
    line-height: ${AmazonTheme.default.lineHeights.fontLineHeightM};
    color: ${AmazonTheme.default.colors.fontPrimaryA};
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  strong { font-family: ${AmazonTheme.default.fontFamilies.fontFamilyA}; }

  *,
  *::after,
  *::before {
    box-sizing: inherit;
  }

  @font-face {
    font-family: "Amazon Ember Bold";
    font-display: block;
    src: url(${window.ASSETS_SERVER_PATH}fonts/AmazonEmber_Bd.ttf) format("truetype");
  }

  @font-face {
    font-family: "Amazon Ember Regular";
    font-display: block;
    src: url(${window.ASSETS_SERVER_PATH}fonts/AmazonEmber_Rg.ttf) format("truetype");
  }

  @font-face {
    font-family: "Amazon Ember Light";
    font-display: block;
    src: url(${window.ASSETS_SERVER_PATH}fonts/AmazonEmber_Lt.ttf) format("truetype");
  }
`;

export default ThemeProviderStyled;
