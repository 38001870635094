// Vendors
import styled from 'styled-components';
import { mobileGridMediaQuery, tabletGridMediaQuery } from '@openbank/cf-ui-framework';
// Types
import { TestIdPropsType } from 'types/test-id.type';

export const DownloadAppModalContainer = styled.div.attrs(({ testId }: TestIdPropsType) => ({
  ...(testId ? { 'data-testid': testId } : {}),
}))`
  width: 100%;

  ${tabletGridMediaQuery} {
    & > div > div {
      gap: ${({ theme }) => theme.spaces.spacingM};
    }
  }

  ${mobileGridMediaQuery} {
    & > div > div {
      padding: ${({ theme }) => theme.spaces.spacingS} ${({ theme }) => theme.spaces.spacingS} 0;
      gap: ${({ theme }) => theme.spaces.spacingM};
    }
  }
`;

export const DownloadAppModalWrapper = styled.div.attrs(({ testId }: TestIdPropsType) => ({
  ...(testId ? { 'data-testid': testId } : {}),
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AssetWrapper = styled.div`
  display: flex;
  justify-content: center;

  ${mobileGridMediaQuery} {
    img {
      width: 100%;
      aspect-ratio: 16 / 9;
    }
  }
`;
