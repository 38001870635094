// Configurations
import { getLinkTypeTrackingConfiguration } from 'containers/financing/views/credit-limit-increase/components/basic/trackings/configurations/credit-limit-increase-tracking.configurations';
// Constants
import { LINK} from './constants/banner-download-app.tracking.constants';
// Types
import { BannerDownloadAppReturnType } from './types/banner-download-app.tracking.type';

const MobileAppTracking = (track: (param: unknown) => void): BannerDownloadAppReturnType => ({
  handlePlayStoreLinkClickTracking: () =>
    track(getLinkTypeTrackingConfiguration(LINK.PLAY_STORE_LINK_CLICK)),
  handleAppStoreLinkClickTracking: () =>
    track(getLinkTypeTrackingConfiguration(LINK.APP_STORE_LINK_CLICK)),
});

export default MobileAppTracking;
