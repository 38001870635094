// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { DOWNLOAD_APP_MODAL } from '../constants/download-app-modal.constants';
// Translations
import { MODAL_PRIMARY_BUTTON } from '../translations/download-app-modal.translations';
// Types
import { DownloadAppConfigurationsPropsType, DownloadAppConfigurationsReturnType } from './types/download-app-modal.configurations.types';

export const getDownloadAppModalConfiguration = ({
  handleCloseModal,
}: DownloadAppConfigurationsPropsType): DownloadAppConfigurationsReturnType => ({
  testId: DOWNLOAD_APP_MODAL.testId,
  showCloseButton: true,
  onHeaderClose: handleCloseModal,
  primaryButtonConfiguration: {
    children: <FormattedMessageComponent id={MODAL_PRIMARY_BUTTON} />,
    disabled: false,
    onClick: handleCloseModal,
  },
});
