// Vendors
import styled from 'styled-components';
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Types
import { TestIdPropsType } from 'types/test-id.type';
// Utils
import {
  mobileGridMediaQuery,
  tabletGridMediaQuery,
} from '@openbank/cf-ui-framework';

export const DownloadAppWrapper = styled.div.attrs(({ testId }: TestIdPropsType) => ({
  ...(testId ? { 'data-testid': testId } : {}),
  role: 'banner',
}))<{
  isInverted?: boolean;
  isFullWidth?: boolean;
}>`
  display: grid;
  grid-template-areas: "containerWrapper";
  grid-template-columns: auto;
  width: 100%;
  height: auto;
  align-items: center;
`;

export const ContainerWrapper = styled.div`
  display: flex;
  grid-area: containerWrapper;
  padding: ${({ theme }) => theme.spaces.spacingM};
  gap: ${({ theme }) => theme.spaces.spacingM};
  ${({ theme }) =>
    `
      ${mobileGridMediaQuery} {
        padding: ${theme.spaces.spacingS};
        flex-direction: column;
        align-items: center;
        gap: ${theme.spaces.spacingS};
      }

      ${tabletGridMediaQuery} {
        align-items: center;
        flex-direction: column;
      }
   `};
`;

export const QrImageStyled = styled.div`
  ${mobileGridMediaQuery} {
    display: none;
  }

  ${tabletGridMediaQuery} {
    display: none;
  }
`;

export const DescriptionTitleParagraphStyled = styled(ParagraphComponent)`
  font-size: ${({ theme }) => theme.fontSizes.fontSizeL};

  ${mobileGridMediaQuery} {
    font-size: ${({ theme }) => theme.fontSizes.fontSizeM};
  }
`;

export const DescriptionStyled = styled.div`
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.spaces.spacingXs};
  flex-direction: column;
  text-align: left;
  ${({ theme }) =>
    `
      ${tabletGridMediaQuery} {
        gap: ${theme.spaces.spacingXxs};
        text-align: center;
      }

      ${mobileGridMediaQuery} {
        text-align: center;
      }
  `};
`;
