// Vendors
import React from 'react';
// Components
import { DownloadAppModal } from 'components/download-app-modal/download-app-modal.component';
// Hooks
import { useDownloadAppModalProviderHook } from './hooks/download-app-modal.provider.hook';
// Types
import { DownloadAppModalProviderProps } from './types/download-app-modal.provider.types';

const DownloadAppModalProvider = ({
  children
}: DownloadAppModalProviderProps): React.ReactElement => {
  const { isModalOpen, handleCloseModal } = useDownloadAppModalProviderHook();

  return (
    <>
      <DownloadAppModal
        {...{
          isModalOpen,
          handleCloseModal,
        }}
      />
      {children}
    </>
  );
};

export { DownloadAppModalProvider };
