// Vendors
import React, { lazy } from 'react';
// Components
const AppPrivateComponent = lazy(() => import('../private/app-private.component'));
const AppProspectComponent = lazy(() => import('../prospect/app-prospect.component'));
const AppNoUserDataComponent = lazy(() => import('../no-user-data/app-no-user-data.component'));
const ONBRedirectComponent = lazy(() => import('components/onb-redirect/onb-redirect.component'));
// Hooks
import { EntrypointAuthorizationHook } from './hooks/entrypoint-authorization.hook';
// Utils
import { isUserLogged } from 'utils/session/session.utils';

const EntrypointAuthorizationComponent = (): React.ReactElement | null => {
  const { noNeedsUserData, onboardingIncomplete } = EntrypointAuthorizationHook();

  switch (true) {
    case onboardingIncomplete && !noNeedsUserData:
      return <ONBRedirectComponent />;
    case !isUserLogged():
      return <AppProspectComponent />;
    case noNeedsUserData:
      return <AppNoUserDataComponent />;
    default:
      return <AppPrivateComponent />;
  }
};

export default EntrypointAuthorizationComponent;
