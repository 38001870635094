// Vendors
import { useState } from 'react';
// Context
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Handlers
import { DownloadAppModalProviderHandlers } from '../handlers/download-app-modal.provider.handlers';
// Utils
import { shouldOpenDownloadAppModal } from './utils/download-app-modal.provider.hook.utils';
// Types
import { DownloadAppModalProviderReturnType } from './types/download-app-modal.provider.hook.types';
import { useLocation } from 'react-router-dom';

export const useDownloadAppModalProviderHook = (): DownloadAppModalProviderReturnType => {
  const { isHybrid } = MobileConnectorContextConsumerHook();
  const { pathname } = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(shouldOpenDownloadAppModal(isHybrid, pathname));

  const { handleCloseModal } = DownloadAppModalProviderHandlers({
    setIsModalOpen,
  });

  return {
    isModalOpen,
    handleCloseModal,
  };
};
