// Constants
import { assetConstants, titleConstants } from '@openbank/cb-ui-commons';

export const BANNER_ROLE = 'banner',
  DEFAULT_BANNER_DOWNLOAD_APP_PROPS = {
    isInverted: false,
    isFullWidth: false,
  },
  BANNER_DOWNLOAD_APP_TEST_ID = 'banner-download-app-container',
  MOBILE_APP_QR_ASSET_PROPS = {
    extension: assetConstants.EXTENSIONS.PNG,
    id: 'qr-zinia-amz-en',
    sizes: {
      height: 96,
      width: 96,
    },
    testId: 'banner-download-app--asset',
    type: assetConstants.TYPES.IMAGE,
  },
  MOBILE_APP_GOOGLE_PLAY_LINK_PROPS = {
    testId: 'banner-download-app--google-play-link',
  },
  MOBILE_APP_GOOGLE_PLAY_DOWNLOAD = 'Cobranded_DownloadApp_Android',
  MOBILE_APP_GOOGLE_PLAY_ASSET_PROPS = {
    extension: assetConstants.EXTENSIONS.SVG,
    id: 'google-store-dark',
    sizes: {
      height: 48,
      width: 162,
    },
    testId: 'google-store-asset',
    type: assetConstants.TYPES.LOGO,
  },
  MOBILE_APP_APP_STORE_LINK_PROPS = {
    testId: 'success-mobile-app-store-link',
  },
  MOBILE_APP_APP_STORE_DOWNLOAD = 'Cobranded_DownloadApp_iOS',
  MOBILE_APP_APP_STORE_ASSET_PROPS = {
    extension: assetConstants.EXTENSIONS.SVG,
    id: 'apple-store-dark',
    sizes: {
      height: 48,
      width: 162,
    },
    testId: 'apple-store-asset',
    type: assetConstants.TYPES.LOGO,
  },
  MOBILE_APP_FIRST_PARAGRAPH = {
    testId: 'banner-download-app--first-paragraph',
    bold: true,
  },
  MOBILE_APP_SECOND_PARAGRAPH = {
    testId: 'banner-download-app--second-paragraph',
  };
