// Vendors
import React from 'react';
// Components
// Providers
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
// Hooks
import FeatureFlagsHook from 'providers/feature-flags/hooks/feature-flags.hook';
// Styles
import { useLocalizationHook } from 'providers/localization/hooks/localization.hook';
import InternationalizationProvider from 'providers/internationalization/internationalization.provider';

const ParallelLoadProvider = ({ children }: ReactChildrenType): React.ReactElement => {
  const { fetching: featureFlagsFetching } = FeatureFlagsHook();
  const { fetching: localizationFetching } = useLocalizationHook();

  const someFetching = featureFlagsFetching || localizationFetching;

  return (
    <InternationalizationProvider externalFetching={someFetching}>
      {children}
    </InternationalizationProvider>
  );
};

export default ParallelLoadProvider;
