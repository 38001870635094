// Constants
import { assetConstants } from '@openbank/cb-ui-commons';

export const DOWNLOAD_APP_MODAL = {
    testId: 'download-app-modal--wrapper',
  },
  CHANGE_PHONE_MODAL_INPUT = {
    testId: 'phone-validation-change-phone-modal-input--wrapper',
    paragraph: {
      bold: true,
      testId: 'phone-validation-change-phone-modal-input--title',
    },
  },
  CHANGE_PHONE_MODAL_WRAPPER = {
    testId: 'phone-validation-change-phone-modal--wrapper',
  },
  CHANGE_PHONE_MODAL_DESCRIPTION_PARAGRAPH = {
    testId: 'phone-validation-change-phone-modal--description',
  },
  CHANGE_PHONE_MODAL_DESKTOP_ASSET_PROPS = {
    extension: assetConstants.EXTENSIONS.PNG,
    id: 'banner-download-desktop',
    sizes: {
      height: 220,
      width: 552,
    },
    testId: 'success-mobile-app--asset',
    type: assetConstants.TYPES.IMAGE,
  },
  CHANGE_PHONE_MODAL_MOBILE_ASSET_PROPS = {
    extension: assetConstants.EXTENSIONS.PNG,
    id: 'banner-download-mobile',
    sizes: {
      height: 220,
      width: 360,
    },
    testId: 'success-mobile-app--asset',
    type: assetConstants.TYPES.IMAGE,
  };
