// Vendors
import React, { Fragment } from 'react';
// Components
import { BannerDownloadAppComponent } from '../banner-download-app/banner-download-app.component';
import { AssetComponent, ModalComponent } from '@openbank/cb-ui-commons';
// Configurations
import { getDownloadAppModalConfiguration } from './configurations/download-app-modal.configurations';
// Constants
import {
  CHANGE_PHONE_MODAL_DESKTOP_ASSET_PROPS,
  CHANGE_PHONE_MODAL_MOBILE_ASSET_PROPS,
  CHANGE_PHONE_MODAL_WRAPPER,
} from './constants/download-app-modal.constants';
// Styled
import {
  AssetWrapper,
  DownloadAppModalContainer,
  DownloadAppModalWrapper,
} from './download-app-modal.styled';
// Types
import { DownloadAppModalPropsType } from './types/download-app-modal.component.types';

export const DownloadAppModal = ({
  handleCloseModal,
  isModalOpen,
}: DownloadAppModalPropsType): React.ReactElement | null => {
  
  const configuration = getDownloadAppModalConfiguration({ handleCloseModal });

  if (!isModalOpen) {
    return <Fragment />;
  }

  return (
    <DownloadAppModalContainer>
      <ModalComponent {...configuration}>
        <DownloadAppModalWrapper {...CHANGE_PHONE_MODAL_WRAPPER}>
          <AssetWrapper>
            <AssetComponent
              {...CHANGE_PHONE_MODAL_MOBILE_ASSET_PROPS}
            />
          </AssetWrapper>
          <BannerDownloadAppComponent />
        </DownloadAppModalWrapper>
      </ModalComponent>
    </DownloadAppModalContainer>
  );
};
