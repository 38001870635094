// Vendors
import React from 'react';
import { ThemeProvider as StyledProvider } from 'styled-components';
import { ReloadedProvider } from '@openbank/ziniaweb-ui-components';
// Styles
import ThemeProviderStyled from './theme.provider.styled';
import { ThemeProviderType } from './types/theme.provider.type';

const ThemeProvider = ({
  children,
  theme,
}: ThemeProviderType): React.ReactElement<ThemeProviderType> => (
  <StyledProvider {...{ theme }}>
    <ReloadedProvider theme={'cobranded_light_regular'} themeName={'cobranded_light_regular'}>
      {children}
    </ReloadedProvider>
    <ThemeProviderStyled />
  </StyledProvider>
);

export default ThemeProvider;
