// Vendors
import styled from 'styled-components';
// Utilities
import { desktopGridMediaQuery } from '@openbank/cf-ui-framework';

export const DownloadAppButtonWrapper = styled.div`
  ${desktopGridMediaQuery} {
    display: none;
  }
`;
