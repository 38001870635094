// Handler
import { BannerDownloadAppHandlers } from '../handlers/banner-download-app.handlers';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
import useCbIntl from 'hooks/useCbIntl';
// Tracking
import MobileAppTracking from '../tracking/banner-download-app.tracking';
// Types
import { BannerDownloadAppHookType } from './types/banner-download-app.hook.type';

export const BannerDownloadAppHook = (): BannerDownloadAppHookType => {
  const intl = useCbIntl();

  const { handlePlayStoreLinkClickTracking, handleAppStoreLinkClickTracking } =
    AppTrackingHook(MobileAppTracking);

  const bannerDownloadAppHandlers = BannerDownloadAppHandlers({
    handlePlayStoreLinkClickTracking,
    handleAppStoreLinkClickTracking,
  });

  return {
    intl,
    ...bannerDownloadAppHandlers,
  };
};