export enum RoutesEnumeration {
  ACCESS_KEY = 'access-key',
  ACCESS_PORTABILITY = 'access-portability',
  ACCESS_REQUEST = 'access-request',
  ACTIVATE = 'activate',
  BENEFICIARY = 'beneficiary',
  BENEFITS_INSURANCE = 'benefits-insurance',
  BLOCK = 'block',
  CANCEL_CONTRACT = 'cancel-contract',
  CARD_CONTROL = 'card-control',
  CARD_INFO = 'card-info',
  CARD_SETTINGS = 'card-settings',
  CARD_STATEMENTS = 'card-statements',
  CATEGORIZER = 'categorizer',
  CHANGE_3D_SECURE = 'change-3d-secure',
  CHANGE_BILLING_CYCLE = 'change-billing-cycle',
  CHANGE_IBAN = 'change-iban',
  CHANGE_PASSWORD = 'change-password',
  CHANGE_PIN = 'change-pin',
  CHARGED_SERVICE_CANCELLED = 'charged-service-cancelled',
  CHECK_3D_SECURE = 'check-3d-secure',
  CONSENTS = 'consents',
  CONTACT = 'contact',
  CONTRACT = 'contract',
  CREATE_INSTALLMENTS_PLAN = 'create-installments-plan',
  CREDIT_LIMIT_INCREASE = 'credit-limit-increase',
  CVV = 'cvv',
  DASHBOARD = 'dashboard',
  DEFECTIVE_PRODUCT = 'defective-product',
  DEPOSIT_MONEY = 'deposit-money',
  DEVICES = 'devices',
  MANAGE_DEVICE = 'manage-device',
  DIFFERENT_AMOUNT_ATM = 'different-amount-atm',
  DOCUMENTATION = 'documentation',
  DUPLICATED_CHARGE = 'duplicated-charge',
  EARLY_REPAYMENT = 'early-repayment',
  EDIT_ADDRESS = 'edit-address',
  EDIT_EMAIL = 'edit-email',
  EDIT_PHONE_NUMBER = 'edit-phone-number',
  EDIT_PHOTO = 'edit-photo',
  FAQS = 'faqs',
  FAST_CASH = 'fast-cash',
  FINANCING = 'financing',
  HIRE_CARD = 'hire-card',
  IBAN_AND_BILLING = 'iban-and-billing',
  INSTALLMENTS_PLAN = 'installments-plan',
  INSURANCES = 'insurances',
  LAST_STATEMENTS = 'last-statements',
  LOGIN = 'login',
  LPI_CANCELLATION = 'lpi-cancellation',
  LPI_CONTRACTING = 'lpi-contracting',
  NOT_FOUND = 'not-found',
  NOT_RECEIVED_PRODUCT = 'not-received-product',
  NOT_RECOGNIZE_CHARGE = 'not-recognize-charge',
  NOTIFICATIONS = 'notifications',
  NOTIFICATIONS_SETTINGS = 'notifications-settings',
  ONBOARDING_LINK = 'stc/onboarding/state',
  OTP = 'otp',
  PACKAGES = 'packages',
  PAYMENT_METHOD = 'payment-method',
  PAYMENT_OTHER_MEANS = 'payment-other-means',
  PERSONAL_INFORMATION = 'personal-information',
  PIN = 'pin',
  POINTS = 'points',
  PORTABILITY_REQUEST = 'portability-request',
  PPI = 'ppi',
  CPI_CANCELLATION = 'cpi-cancellation',
  CPI_CONTRACTING = 'cpi-contracting',
  RECOVERY_KEY = 'recovery-key',
  REPORT_A_PROBLEM = 'report-a-problem',
  REQUEST_NEW_CARD = 'new-card',
  REQUEST_SIGNATURE_KEY = 'request-signature-key',
  SECURE_ELECTRONIC_COMMERCE = 'secure-electronic-commerce',
  SECURITY = 'security',
  SIGNATURE_KEY = 'signature-key',
  STATEMENTS = 'statements',
  SUBSCRIPTION_PASSED = 'subscription-passed',
  SUPPORT = 'support',
  TRANSACTIONS = 'transactions',
  UPDATE = 'update',
}
