// Constants
import {
  MOBILE_APP_APP_STORE_DOWNLOAD,
  MOBILE_APP_GOOGLE_PLAY_DOWNLOAD,
} from '../constants/banner-download-app.constants';
// Types
import {
  AppStoreLinkClickHandlerPropsType,
  BannerDownloadAppHandlersPropsType,
  BannerDownloadAppHandlersReturnType,
  PlayStoreLinkClickHandlerPropsType,
} from './types/banner-download-app.handlers.type';
// Utils
import { getConfigurationValue } from 'utils/get-configuration-value';

const playStoreLinkClickHandler = ({
  handlePlayStoreLinkClickTracking,
}: PlayStoreLinkClickHandlerPropsType): void => {
  handlePlayStoreLinkClickTracking();
  window.open(getConfigurationValue(MOBILE_APP_GOOGLE_PLAY_DOWNLOAD), '_blank');
};

const appStoreLinkClickHandler = ({
  handleAppStoreLinkClickTracking,
}: AppStoreLinkClickHandlerPropsType): void => {
  handleAppStoreLinkClickTracking();
  window.open(getConfigurationValue(MOBILE_APP_APP_STORE_DOWNLOAD), '_blank');
};

export const BannerDownloadAppHandlers = ({
  handleAppStoreLinkClickTracking,
  handlePlayStoreLinkClickTracking,
}: BannerDownloadAppHandlersPropsType): BannerDownloadAppHandlersReturnType => ({
  handlePlayStoreLinkClick: () => playStoreLinkClickHandler({ handlePlayStoreLinkClickTracking }),
  handleAppStoreLinkClick: () => appStoreLinkClickHandler({ handleAppStoreLinkClickTracking }),
});
