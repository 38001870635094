// Vendors
import { LinkComponentType } from '@openbank/cb-ui-commons';
// Constants
import {
  MOBILE_APP_APP_STORE_LINK_PROPS,
  MOBILE_APP_GOOGLE_PLAY_LINK_PROPS,
} from '../constants/banner-download-app.constants';
// Hooks
import { BannerDownloadAppHook } from '../hooks/banner-download-app.hook';

const getGooglePlayLinkConfiguration = (): LinkComponentType => {
  const { handlePlayStoreLinkClick } = BannerDownloadAppHook();

  return {
    ...MOBILE_APP_GOOGLE_PLAY_LINK_PROPS,
    onClick: handlePlayStoreLinkClick,
  };
};

const getAppStoreLinkConfiguration = (): LinkComponentType => {
  const { handleAppStoreLinkClick } = BannerDownloadAppHook();

  return {
    ...MOBILE_APP_APP_STORE_LINK_PROPS,
    onClick: handleAppStoreLinkClick,
  };
};

export { getAppStoreLinkConfiguration, getGooglePlayLinkConfiguration };
