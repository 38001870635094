// Vendors
import React from 'react';
import { IntlProvider } from 'react-intl';
import { FetchErrorComponent } from '@openbank/cb-ui-commons/';
// Hooks
import useInternationalizationHook from './hooks/internationalization.hook';
// Styles
import { LayoutLoaderStyled } from 'styles/app.styled';
// Types
import { InternationalizationProviderPropsType } from './types/internationalization-provider-props.type';

const InternationalizationProvider = ({ children, externalFetching }: InternationalizationProviderPropsType): React.ReactElement => {
  const { locale, fetching, translations: messages } = useInternationalizationHook();
  const someFetching = externalFetching || fetching;

  return (
    <LayoutLoaderStyled {...{ fetching: someFetching }}>
      <FetchErrorComponent {...{ fetching: someFetching }}>
        <IntlProvider {...{ locale, messages }}>{children}</IntlProvider>
      </FetchErrorComponent>
    </LayoutLoaderStyled>
  );
};

export default InternationalizationProvider;
