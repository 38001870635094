// Initializations
import { mobileCommunicator } from '../initialization/mobile-connector.initialization';

export function asyncCallFactory<T, V>(type: string): (data: T) => Promise<V> {
  return (data: T) => {
    return mobileCommunicator.request<T, V>(type, data);
  };
}

export function asyncListenFactory<T>(
  type: string
): (callbackSuccess: (data: T) => void, callbackError?: (error: unknown) => void) => void {
  return (callbackSuccess, callbackError) =>
    mobileCommunicator.listen(type, callbackSuccess, callbackError);
}
