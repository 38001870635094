// Vendors
import React from 'react';
import { LinkComponent, linkConstants } from '@openbank/cb-ui-commons';
// Components
import BoldMessageComponent from 'components/bold-message/bold-message.component';
// Constants
import { MATCH_ESCAPED_VARIABLES } from './constants/useCbIntl.utils.constants';
// Types
import {
  InputType,
  LinkIntlUtilType,
  ObjectWithPropsAndChildren,
} from './types/useCbIntl.utils.type';
import { isSafariNativeBrowser } from '@openbank/cf-ui-framework';

export const makeChunkBoldIntlUtil = (
  chunk: React.ReactNode,
  testId?: string
): React.ReactElement => (
  <BoldMessageComponent {...{ testId }} isSafariBrowser={isSafariNativeBrowser()}>
    {chunk}
  </BoldMessageComponent>
);

export const makeChunkLinkIntlUtil = ({
  chunk,
  onClick,
  size = linkConstants.SIZES.NORMAL,
  type = linkConstants.TYPES.ACTION_SECONDARY,
}: LinkIntlUtilType): React.ReactElement => (
  <LinkComponent {...{ onClick, size, type }}>{chunk}</LinkComponent>
);

const replaceInString = (text: string, dictionary: InputType['dictionary']): string => {
  const matches = text.match(new RegExp(MATCH_ESCAPED_VARIABLES, 'g'));

  if (matches) {
    matches.forEach(match => {
      const key = match.replace(/[{}]/g, '');
      if (dictionary[key]) {
        text = text.replace(match, dictionary[key]);
      }
    });
  }
  return text;
};

const replaceItemValuesFromArray = (
  item: string | ObjectWithPropsAndChildren,
  dictionary: InputType['dictionary']
) => {
  if (typeof item === 'string') {
    return replaceInString(item, dictionary);
  }
  if (item && typeof item === 'object' && item.props && Array.isArray(item.props.children)) {
    const newChildren = item.props.children.map(child =>
      typeof child === 'string' ? replaceInString(child, dictionary) : child
    );
    return { ...item, props: { ...item.props, children: newChildren } };
  }
  return item;
};

export const replaceWithDictionaryValue = ({ dictionary, input }: InputType): any => {
  if (!dictionary) {
    return input;
  }
  if (typeof input === 'string') {
    return replaceInString(input, dictionary);
  }
  if (Array.isArray(input)) {
    return input.map((item: string | ObjectWithPropsAndChildren) =>
      replaceItemValuesFromArray(item, dictionary)
    );
  }
  return input || '';
};
