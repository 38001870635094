// Vendors
import React from 'react';
import { AssetComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Styles
import {
  QrImageStyled,
  DescriptionStyled,
  ContainerWrapper,
  DownloadAppWrapper,
  DescriptionTitleParagraphStyled,
} from './banner-download-app.styled';
// Constants
import {
  BANNER_DOWNLOAD_APP_TEST_ID,
  BANNER_ROLE,
  DEFAULT_BANNER_DOWNLOAD_APP_PROPS,
  MOBILE_APP_FIRST_PARAGRAPH,
  MOBILE_APP_QR_ASSET_PROPS,
  MOBILE_APP_SECOND_PARAGRAPH,
} from './constants/banner-download-app.constants';
// Components
import { DownloadAppButtonComponent } from './components/download-app-button.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Types
import { BannerDownloadAppComponentProps } from './types/banner-download-app.component.types';
// Translations
import {
  TITLE,
  DESCRIPTION_MOBILE,
} from './translations/banner-download-app.translations';

export const BannerDownloadAppComponent = ({
  isInverted = DEFAULT_BANNER_DOWNLOAD_APP_PROPS.isInverted,
  isFullWidth = DEFAULT_BANNER_DOWNLOAD_APP_PROPS.isFullWidth,
}: BannerDownloadAppComponentProps): React.ReactElement => {
  return (
    <DownloadAppWrapper
      role={BANNER_ROLE}
      data-testid={BANNER_DOWNLOAD_APP_TEST_ID}
      {...{ isInverted, isFullWidth }}
    >
      <ContainerWrapper>
        <QrImageStyled>
          <AssetComponent {...MOBILE_APP_QR_ASSET_PROPS} />
        </QrImageStyled>
        <DescriptionStyled>
          <DescriptionTitleParagraphStyled {...MOBILE_APP_FIRST_PARAGRAPH}>
            <FormattedMessageComponent id={TITLE} />
          </DescriptionTitleParagraphStyled>
          <ParagraphComponent {...MOBILE_APP_SECOND_PARAGRAPH}>
            <FormattedMessageComponent id={DESCRIPTION_MOBILE} values={{ br: <br /> }} />
          </ParagraphComponent>
        </DescriptionStyled>
        <DownloadAppButtonComponent />
      </ContainerWrapper>
    </DownloadAppWrapper>
  );
};
