// Enumerations
import { SessionEnumeration } from 'enumerations/sessions.enumerations';
import { isMobileBrowserDevice } from '@openbank/cf-ui-framework';
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

export const shouldOpenDownloadAppModal = (isHybrid: boolean, pathname: string): boolean => {
  return (
    pathname === `/${RoutesEnumeration.LOGIN}` &&
    !isHybrid &&
    isMobileBrowserDevice() &&
    sessionStorage.getItem(SessionEnumeration.MODAL_DOWNLOAD_APP_OPENED_SESSION_KEY) !== 'true'
  );
};
