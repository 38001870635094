// Vendors
import React, { ReactElement } from 'react';
// Components
import { LoaderComponent } from '@openbank/cb-ui-commons';
// Constants
import { SUSPENSE_LOADER_PROPS } from './constants/suspense-loader.constants';
// Styles
import { SuspenseLoaderWrapperStyled } from './suspense-loader.styled';

export const SuspenseLoaderComponent = (): ReactElement => (
  <SuspenseLoaderWrapperStyled>
    <LoaderComponent {...SUSPENSE_LOADER_PROPS} />
  </SuspenseLoaderWrapperStyled>
);
