// Vendors
import styled from 'styled-components';

export const SuspenseLoaderWrapperStyled = styled.div`
  flex: 1;
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
`;
