// Vendors
import React, { useMemo, createContext, useContext } from 'react';
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
// Hooks
import MobileConnectorHook from '../hooks/mobile-connector.hook';
// Types
import { MobileConnectorContextType } from './types/mobile-connector.provider.context.type';
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { LayoutLoaderStyled } from 'styles/app.styled';

const MobileConnectorContext = createContext<MobileConnectorContextType>({
  fetching: false,
  isHybrid: false,
  isInCreditLimitIncrease: false,
  notchSize: 0,
  onNavigateHybridFlow: () => null,
  onHybridFlow: () => ({}),
  handleSendToMobileAccessToken: () => null,
  handleOpenWebViewBrowser: () => null,
  handleOpenExternalUrlOnHybridFlow: () => null,
});

const MobileConnectorContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<MobileConnectorContextType>> => {
  const {
    fetching,
    isHybrid,
    isInCreditLimitIncrease,
    notchSize,
    onNavigateHybridFlow,
    onHybridFlow,
    handleSendToMobileAccessToken,
    handleOpenWebViewBrowser,
    handleOpenExternalUrlOnHybridFlow,
  } = MobileConnectorHook();

  const contextValue = useMemo(
    () => ({
      fetching,
      isHybrid,
      isInCreditLimitIncrease,
      notchSize,
      onHybridFlow,
      onNavigateHybridFlow,
      handleSendToMobileAccessToken,
      handleOpenWebViewBrowser,
      handleOpenExternalUrlOnHybridFlow,
    }),
    [
      fetching,
      isHybrid,
      isInCreditLimitIncrease,
      notchSize,
      onNavigateHybridFlow,
      onHybridFlow,
      handleSendToMobileAccessToken,
      handleOpenWebViewBrowser,
      handleOpenExternalUrlOnHybridFlow,
    ]
  );

  return (
    <MobileConnectorContext.Provider value={contextValue}>
      <LayoutLoaderStyled {...{ fetching }}>
        <FetchErrorComponent {...{ fetching }}>{children}</FetchErrorComponent>
      </LayoutLoaderStyled>
    </MobileConnectorContext.Provider>
  );
};

export const MobileConnectorContextConsumerHook = (): MobileConnectorContextType =>
  useContext(MobileConnectorContext);

export default MobileConnectorContextProvider;
